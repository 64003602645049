import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import hometbn from "../Assets/images/home-btn.svg";
import devops from "../Assets/images/Dev-ops-details.svg";
import "./modulesCss/GeneralForPages.css";
import { scrollToTop } from "../App";
const DevOps = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container container-two-dev">
      <div className="d-flex first-container-dev">
        <div className="btn-home btn-home-position">
          <Link to={"/"}>
            <img src={hometbn} width="35" height="35" alt="" />
          </Link>
          <span className="mt-1">Home</span>
        </div>
        <div className="first-section-dev text-start">
          <h1>Devops</h1>
          <p>
            Enhance the resilience and efficiency of your enterprise-grade
            products with Cipher top-tier DevOps services. Our expert team of
            DevOps engineers specializes in implementing cutting-edge
            technologies and industry best practices to optimize your systems
            and maximize throughput. Through our comprehensive DevOps consulting
            services, we enable businesses to streamline their development
            processes, reduce time-to-market, and deliver feature-rich products
            to their target audience. By automating cloud architecture and
            implementing continuous integration and delivery, our DevOps
            solutions empower organizations to achieve their goals efficiently
            and reliably, keeping pace with the rapidly evolving business
            landscape.
          </p>
        </div>
        <div className="right-section-dev">
          <img className="img-section-right-dev" src={devops} alt="" />
        </div>
      </div>
      <div className="heading-bg">
        <h2 className="services-text">Solutions Offered</h2>
        <span className="blue-line-dev"></span>
      </div>
      <div className="second-section-software text-start">
        <h2>Data Centers</h2>
        <p>
          Our DevOps team specializes in managing and optimizing data centers to
          ensure efficient and secure operations. We have extensive experience
          in setting up and maintaining data center infrastructure, including
          server provisioning, network configuration, and storage management.
        </p>
        <h2>Architecture</h2>
        <p>
          Our DevOps experts are skilled in designing and implementing scalable
          and resilient architectures for software applications. We follow
          industry best practices and leverage modern technologies to create
          robust and flexible architectures that can handle high traffic loads
          and ensure high availability.
        </p>
        <h2>Automation Solutions</h2>
        <p>
          We provide automation solutions to streamline and accelerate software
          development and deployment processes. Our DevOps engineers are
          proficient in using tools like Jenkins, Ansible, and Docker to
          automate tasks such as code testing, build and deployment, and
          infrastructure provisioning. This helps reduce manual effort, improve
          efficiency, and minimize errors.
        </p>
        <h2>Integrations</h2>
        <p>
          Our DevOps team specializes in integrating various software systems
          and tools to create a seamless and efficient development and
          operations environment. We have experience in integrating source code
          management systems, issue tracking systems, continuous integration and
          delivery pipelines, and monitoring and logging tools. This enables
          smooth collaboration and enhances productivity across the software
          development lifecycle.
        </p>
      </div>
      {/* <div className="heading-bg">
        <h2 className="services-text">Technology We Use</h2>
        <span className="blue-line-dev"></span>
      </div> */}
    </div>
  );
};

export default DevOps;
