import React from "react";
import "./modulesCss/howWeWork.css";
import "./modulesCss/portfolio.css";
import hometbn from "../Assets/images/home-btn.svg";
import { useNavigate } from "react-router-dom";
import imageOne from "../Assets/images/realestatemain.png";

const CaseStudy = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="container text-start">
      <div className="container-two-port">
        <div className="first-section-work">
          <div className="btn-home-work cursor-pointer" onClick={handleGoBack}>
            <img src={hometbn} width="35" height="35" alt="" />
            <span className="mt-1">Back</span>
          </div>
          <h1 className="primary-head">Case Study</h1>
        </div>
      </div>
      <div className="container ">
        <div className="d-flex ">
          <div className="heading-port container-three-port">
            <h3>
              Real State <br /> Project
            </h3>
          </div>
          <span className="blue-line-vertical"></span>
          <p className="port-pera">
            “RealStateX” is revolutionizing real estate transactions with
            verified sellers and buyers, instilling confidence through a
            meticulous verification process. The platform features an
            out-of-the-box security layer, employing advanced encryption and
            protocols to secure user data and financial transactions.
          </p>
        </div>
        <div className="container-three-port mt-5 ">
          <img className="big-image" src={imageOne} alt="" />
          <p className="mt-5">
            Artificial intelligence enhances the property search experience,
            analyzing user preferences for personalized recommendations.
            Blockchain technology takes center stage with RealStateX,
            introducing smart contracts for secure property verification and
            transfer. This innovative approach automates transactions, reducing
            fraud risks and enhancing transparency. The platform sets a new
            standard for reliability and efficiency in real estate transactions.
            “RealStateX” isn't just a platform; it's a transformative force in
            the industry.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CaseStudy;
