import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import hometbn from "../Assets/images/home-btn.svg";
import mob from "../Assets/images/digital-marketing-details.svg";
import "./modulesCss/GeneralForPages.css";
import { scrollToTop } from "../App";
const DigitalMarketing = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container container-two-mob">
      <div className="d-flex first-container-dev">
        <div className="btn-home btn-home-position">
          <Link to={"/"}>
            <img src={hometbn} width="35" height="35" alt="" />
          </Link>
          <span className="mt-1">Home</span>
        </div>
        <div className="first-section-dev text-start">
          <h1>
            Digital <br />
            Marketing
          </h1>
          <p>
            Our startup marketing agency adopts a comprehensive and integrated
            approach. We start by formulating a robust strategy and creating a
            captivating website concept. We then ensure consistent engagement
            across all relevant channels, continuously test and enhance
            performance, and leverage the best marketing practices, extensive
            analytics, and development enhancements to drive accelerated growth
            and efficiency.
          </p>
        </div>
        <div className="right-section-dev">
          <img className="img-section-right-dev" src={mob} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DigitalMarketing;
