import React from "react";
import "./header.css";
import ciper from "../Assets/images/image 3.png";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <nav
      style={{ zIndex: 1000, paddingBottom: "13px" }}
      className="navbar navbar-expand-lg navbar-dark header-main"
    >
      <div className="container">
        <Link to={"/"} className="navbar-brand cipher-text">
          <img src={ciper} height="40" alt="" />
          {/* <img src={ciper} height="40" alt="" /> */}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse about-us-text" id="navbarNav">
          <ul className="navbar-nav mt-1">
            <li className="nav-item">
              <Link to={"/about-us"} className="nav-link">
                About us
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to={"/Services"} className="nav-link">
                Services
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to={"/How-We-Work"} className="nav-link">
                How We Work
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/clients"} className="nav-link">
                Clients
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/Contact"} className="nav-link nomargin">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
