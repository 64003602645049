import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./modulesCss/howWeWork.css";
import hometbn from "../Assets/images/home-up-button.svg";
import { Link } from "react-router-dom";

const HowWeWork = () => {
  const [currentActiveKey, setCurrentActiveKey] = useState(null);

  const toggleActiveKey = (key) => {
    console.log("keykey", key);
    setCurrentActiveKey(currentActiveKey === key ? null : key);
  };

  return (
    <div className="container container-two-work">
      <div className="first-section-work">
        <div className="btn-home-work">
          <Link to={"/"}>
            <img src={hometbn} width="35" height="35" alt="" />
          </Link>
          <span className="mt-1">Home</span>
        </div>
        <h1 className="work-head animate__animated animate__fadeIn">
          How We Work
        </h1>
      </div>
      <div className="work-center">
        <Accordion>
          <Accordion.Item
            className={`${
              currentActiveKey === "0" ? "bluehh" : ""
            } animate__animated animate__fadeInLeft animate__faster`}
            eventKey="0"
          >
            <Accordion.Header onClick={() => toggleActiveKey("0")}>
              <b>01</b> <span>Consultation</span>
            </Accordion.Header>
            <Accordion.Body>
              <hr />
              At the start of our process, we engage in a comprehensive
              discussion to identify the specific needs of your business and
              explore how technology can be leveraged to optimize your
              operations.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="1"
            className={`${
              currentActiveKey === "1" ? "bluehh" : ""
            } animate__animated animate__fadeInLeft animate__fast`}
          >
            <Accordion.Header onClick={() => toggleActiveKey("1")}>
              {" "}
              <b>02</b>
              <span> Implementation</span>
            </Accordion.Header>
            <Accordion.Body>
              <hr />
              With a clear understanding of your requirements, we seamlessly
              transition into the implementation phase. Our experienced team of
              professionals works diligently to bring your vision to life,
              employing cutting-edge technologies and industry best practices.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="2"
            className={`${
              currentActiveKey === "2" ? "bluehh" : ""
            } animate__animated animate__fadeInLeft`}
          >
            <Accordion.Header onClick={() => toggleActiveKey("2")}>
              {" "}
              <b>03</b> <span> Monitoring and Optimization</span>
            </Accordion.Header>
            <Accordion.Body>
              <hr />
              Ensuring the ongoing success of your projects, we implement robust
              monitoring systems. This allows us to proactively identify any
              potential issues and optimize performance continually. Our
              dedication to excellence extends beyond the initial
              implementation, providing you with reliable and efficient
              solutions.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="3"
            className={`${
              currentActiveKey === "3" ? "bluehh" : ""
            } animate__animated animate__fadeInLeft`}
          >
            <Accordion.Header onClick={() => toggleActiveKey("3")}>
              {" "}
              <b>04</b> <span>Reporting and Communication</span>
            </Accordion.Header>
            <Accordion.Body>
              <hr />
              Transparent communication is integral to our work ethic. We keep
              you informed at every step of the process, providing regular
              reports on project milestones, performance metrics, and any
              adjustments made. This collaborative approach ensures that your
              expectations are not just met but exceeded. <br /> At Cipher
              Development, our work methodology is a dynamic and collaborative
              process that evolves with your business needs. From the initial
              consultation to ongoing monitoring and reporting, we are committed
              to delivering results that drive your business forward.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default HowWeWork;
