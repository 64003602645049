import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import hometbn from "../Assets/images/home-btn.svg";
import webDesign from "../Assets/images/web-design-details.svg";
import { scrollToTop } from "../App";
const WebDesign = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container container-two-dev">
      <div className="d-flex first-container-dev">
        <div className="btn-home btn-home-position">
          <Link to={"/"}>
            <img src={hometbn} width="35" height="35" alt="" />
          </Link>
          <span className="mt-1">Home</span>
        </div>
        <div className="first-section-dev text-start">
          <h1>
            Web Design & <br /> Development{" "}
          </h1>
          <p>
            Cipher, with its extensive expertise and cohesive team, specializes
            in delivering intuitive and modern websites, web portals, and other
            web products that revitalize business processes, drive digital
            transformation, and optimize business workflows. Partner with our
            team to create a unique and efficient web solution.
          </p>
        </div>
        <div className="right-section-dev">
          <img className="img-section-right-dev" src={webDesign} alt="" />
        </div>
      </div>
      <div className="heading-bg">
        <h2 className="services-text">visually captivating web solutions</h2>
        <span className="blue-line-web"></span>
      </div>
      <div className="second-section-software text-start">
        <h2>Digital strategy & consulting</h2>
        <p>
          Our experienced R&D team provides comprehensive product consultation
          services to our clients. Whether you are looking to develop a web or
          mobile solution, our team of professionals will guide you through the
          entire process and assist you in selecting the most suitable
          technologies for your project
        </p>
        <h2>UI/UX Design</h2>
        <p>
          Our team of senior product designers excels at creating seamless user
          journeys and visually captivating interfaces. We prioritize your
          requirements and preferences, conduct thorough research on your target
          audience, and deliver product designs that resonate with them.
        </p>
        <h2>Front end Development</h2>
        <p>
          By outsourcing our Our mobile and website development company
          specializes in creating responsive and user-friendly interfaces for
          both web and mobile platforms. Our team of specialists is highly
          skilled in utilizing advanced front-end technologies such as HTML5,
          CSS3, and JavaScript to deliver top-notch solutions that meet your
          specific requirements and exceed your expectations.software
          development services, you can easily focus on your business while we
          provide enterprise-level support for your mission-critical
          environments following the DevOps approach and ITIL process.
        </p>
        <h2>Web Development</h2>
        <p>
          Experience the power of cutting-edge web development with our
          top-rated company. We specialize in creating reliable and visually
          appealing online solutions that enable businesses to effectively
          communicate with their clients across multiple devices. Whether you're
          a startup or an established business, our web development services are
          tailored to meet your specific needs and help you make a strong online
          presence.
        </p>
        <h2>QA & Solution Testing</h2>
        <p>
          At Cipher Dev, we have a highly skilled team of QA engineers who are
          dedicated to ensuring that your product meets the highest standards.
          We utilize advanced technologies and software testing procedures to
          develop the best web and mobile development solutions.
        </p>
      </div>
    </div>
  );
};

export default WebDesign;
