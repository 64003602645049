import React from "react";
import "./modulesCss/howWeWork.css";
import "./modulesCss/portfolio.css";
import hometbn from "../Assets/images/home-btn.svg";
import { useNavigate } from "react-router-dom";
import imageOne from "../Assets/images/helixmain.png";

const CaseStudyHelix = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="container text-start">
      <div className="container-two-port">
        <div className="first-section-work">
          <div className="btn-home-work cursor-pointer" onClick={handleGoBack}>
            <img src={hometbn} width="35" height="35" alt="" />
            <span className="mt-1">Back</span>
          </div>
          <h1 className="primary-head">Case Study</h1>
        </div>
      </div>
      <div className="container ">
        <div className="d-flex ">
          <div className="heading-port container-three-port">
            <h3>
              Helix <br /> Project
            </h3>
          </div>
          <span className="blue-line-vertical"></span>
          <p className="port-pera">
            Discover how Cipher Development transformed a startup's vision into
            reality through customized software solutions. By leveraging
            offshore development, we optimized efficiency and quality. Our
            expert DevOps support ensured seamless operations, allowing the
            client to focus on core business aspects.
          </p>
        </div>
        <div className="container-three-port mt-5">
          <img className="big-image" src={imageOne} alt="" />
          <p className="mt-5">
            In mobile app and game development, we delivered innovative
            solutions for iOS and Android platforms. A groundbreaking case in
            blockchain showcased our expertise in NFTs, Smart Contracts, and
            Dapps. Our web design services revitalized digital presence, driving
            transformation and workflow optimization. With strategic
            consultation and UI/UX design, we created a cohesive user
            experience. Front-end development using advanced technologies
            solidified our commitment to exceptional results.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyHelix;
