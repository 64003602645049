import React from "react";
import "./modulesCss/howWeWork.css";
import "./modulesCss/portfolio.css";
import hometbn from "../Assets/images/home-btn.svg";
import { useNavigate } from "react-router-dom";
import imageOne from "../Assets/images/iridemain.png";

const CaseStudyIride = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container text-start">
      <div className="container-two-port">
        <div className="first-section-work">
          <div className="btn-home-work cursor-pointer" onClick={handleGoBack}>
            <img src={hometbn} width="35" height="35" alt="" />
            <span className="mt-1">Back</span>
          </div>
          <h1 className="primary-head">Case Study</h1>
        </div>
      </div>
      <div className="container ">
        <div className="d-flex ">
          <div className="heading-port container-three-port">
            <h3>
              iRide <br /> Project
            </h3>
          </div>
          <span className="blue-line-vertical"></span>
          <p className="port-pera">
            “iRide” redefines the car rental experience with a focus on
            transparency, trust, and simplicity. Verified buyers and sellers
            form the foundation of this platform, ensuring a secure environment
            for all participants. The application boasts a transparent payment
            flow, eliminating confusion and providing users with a clear
            understanding of financial transactions.
          </p>
        </div>
        <div className="container-three-port mt-5">
          <img className="big-image" src={imageOne} alt="" />
          <p className="mt-5">
            Clear communication between lessors and lessees is facilitated,
            fostering a smooth and efficient interaction throughout the car
            rental process. “iRide” stands out by operating on a no-commission
            basis, removing additional fees and charges, and offering a
            cost-effective solution for both lessors and lessees. <br /> In a
            departure from conventional models, “iRide” sets a new standard in
            the car rental industry, where trust, transparency, and a
            commission-free approach converge to create a reliable and
            user-friendly platform for renting and leasing cars.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyIride;
