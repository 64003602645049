import "animate.css";
import "./App.css";
import MainRoutes from "./MainRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

export const scrollToTop = () => {
  const body = document.querySelector("#root");
  body.scrollIntoView(
    {
      behavior: "smooth",
    },
    500
  );
};

function App() {
  return (
    <div className="App">
      <Router>
        <div className="flex-layout flex-layout-bluebg">
          <Header />
          <MainRoutes />
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
