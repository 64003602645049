import React, { useEffect } from "react";
import softdev from "../Assets/images/soft-dev-right.svg";
import "./modulesCss/GeneralForPages.css";
import { Link } from "react-router-dom";
import hometbn from "../Assets/images/home-btn.svg";
import { scrollToTop } from "../App";

const SoftDev = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container container-two" id="soft-dev-page">
      <div className="d-flex first-container-soft">
        <div className="btn-home btn-home-position">
          <Link to={"/"}>
            <img src={hometbn} width="35" height="35" alt="" />
          </Link>
          <span className="mt-1">Home</span>
        </div>
        <div className="first-section-soft text-start">
          <h1>
            Software <br /> Development
          </h1>
          <p>
            Drive your business towards success with our customized software
            development solutions. Our comprehensive and adaptable approach
            ensures that your specific requirements are met, providing a
            reliable and scalable solution. We offer expert consultations and
            support throughout the entire software development lifecycle,
            guiding you every step of the way!
          </p>
        </div>
        <div className="right-section-soft">
          <img className="img-section-right" src={softdev} alt="" />
        </div>
      </div>
      <div className="heading-bg">
        <h2 className="services-text">Services</h2>
        <span className="blue-line"></span>
      </div>
      <div className="second-section-software text-start">
        <h2>Custom Software Solutions</h2>
        <p>
          Whether you are a startup or a fully established business, our
          software development firm is dedicated to providing comprehensive
          guidance throughout every stage of the software development life
          cycle. From conceptualization and consulting to development and
          support, our team is here to meet all of your custom software
          development needs.
        </p>
        <h2 className="text-start">Offshore Software Development</h2>
        <p className="text-start">
          Enhance the efficiency and quality of your software projects by
          outsourcing our software application developers. We conduct thorough
          screening and verification of the business background for offshore,
          nearshore, and onshore service providers. Are you ready to entrust
          your software to the best software development company?
        </p>
        <h2 className="text-start">Managed Service & Devops</h2>
        <p className="text-start">
          By outsourcing our software development services, you can easily focus
          on your business while we provide enterprise-level support for your
          mission-critical environments following the DevOps approach and ITIL
          process.
        </p>
      </div>
    </div>
  );
};

export default SoftDev;
