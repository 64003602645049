import React, { useEffect } from "react";
import "./modulesCss/portfolio.css";
import hometbn from "../Assets/images/home-up-button.svg";
// import imageOne from "../Assets/images/realestatex.svg";
// import imageTwo from "../Assets/images/iRide.svg";
// import imageThree from "../Assets/images/helix.svg";
// import imageFour from "../Assets/images/realestatextwo.svg";
// import imageFive from "../Assets/images/iridetwo.svg";
// import imageSix from "../Assets/images/helixtwo.svg";
import wind from "../Assets/images/Asset-17.webp";
import direct from "../Assets/images/direct-Tv.webp";
import xfinity from "../Assets/images/xfinity.webp";
import spectrum from "../Assets/images/spectrum.webp";
import earthlink from "../Assets/images/earthlink.webp";
import viasat from "../Assets/images/viasat.webp";
import att from "../Assets/images/at&t.webp";
import { Link } from "react-router-dom";
import { scrollToTop } from "../App";
// import Masonry from "react-responsive-masonry";
const Portfolio = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  // const images = [
  //   imageOne,
  //   imageTwo,
  //   imageThree,
  //   imageFour,
  //   imageFive,
  //   imageSix,
  // ];

  // const imageClasses = [
  //   "image-container",
  //   "iride-image-container",
  //   "helix-image-container",
  // ];

  return (
    <div className="container text-start mb-5">
      <div className="container-two-port">
        <div className="first-section-work">
          <div className="btn-home-work">
            <Link to={"/"}>
              <img src={hometbn} width="35" height="35" alt="" />
            </Link>
            <span className="mt-1">Home</span>
          </div>
          <h1 className="primary-head animate__animated animate__fadeInUp">
            Clients
          </h1>
        </div>
      </div>
      <div className="container animate__animated animate__fadeIn">
        <div className="d-flex">
          <div className="heading-port container-three-port">
            <h3 className="work-width">
              Our Work <br /> Done Right
            </h3>
          </div>
          <span className="blue-line-vertical"></span>
          <p className="port-pera">
            Cipher Development takes pride in delivering bespoke software
            solutions, offering offshore development expertise. we have
            consistently exceeded the expectations of our esteemed clients. Our
            commitment to digital strategy, UI/UX design, and responsive
            front-end development has empowered businesses to thrive in the
            digital age. Join hands with Cipher Development to unlock the full
            potential of your business and embark on a journey of unparalleled
            success.
          </p>
        </div>
        <div className="new-logos mt-5 mb-5 animate__animated animate__fadeIn">
          <div className="d-flex mt-5 wrap-logo">
            <img src={wind} alt="wind" />
            <img src={direct} alt="direct-tv" className="mt-logo" />
            <img src={xfinity} alt="Xfinity" className="mt-logo" />
            <img src={earthlink} alt="earthlink" className="mt-logo" />
          </div>
          <div className="d-flex wrap-logo">
            <img src={spectrum} alt="spectrum" className="mt-logo" />

            <img src={viasat} alt="viasat" className="mt-logo" />
            <img src={att} alt="att" className="mt-logo" />
          </div>
        </div>
        {/* <div className="container-three-port mt-5 mb-5">
          <Masonry columnsCount={3} gutter={30}>
            {images.map((image, i) => (
              <Link
                className="new-container-bg"
                to={`/img-details/${i}`}
                key={i}
              >
                <div className={imageClasses[i % imageClasses.length]}>
                  <img
                    src={image}
                    style={{ width: "100%", display: "block" }}
                    alt={`${i + 1}`}
                    className="image"
                  />
                </div>
              </Link>
            ))}
          </Masonry>
        </div> */}
      </div>
    </div>
  );
};

export default Portfolio;
