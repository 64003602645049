import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import hometbn from "../Assets/images/home-btn.svg";
import mob from "../Assets/images/mob-games-details.svg";
import "./modulesCss/GeneralForPages.css";
import { scrollToTop } from "../App";

const MobGamesApp = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container container-two-mob">
      <div className="d-flex first-container-dev">
        <div className="btn-home btn-home-position">
          <Link to={"/"}>
            <img src={hometbn} width="35" height="35" alt="" />
          </Link>
          <span className="mt-1">Home</span>
        </div>
        <div className="first-section-dev text-start">
          <h1>
            Mobile Games <br /> & App
          </h1>
          <p>
            Our mobile app development services specialize in creating iOS,
            Android, and cross-platform applications that seamlessly complement
            or replace web solutions. Additionally, we offer Mobile game
            development for both iOS and Android platforms.
          </p>
        </div>
        <div className="right-section-dev">
          <img className="img-section-right-dev" src={mob} alt="" />
        </div>
      </div>
    </div>
  );
};

export default MobGamesApp;
