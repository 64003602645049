import React from "react";
import "./modulesCss/aboutUs.css";
import aboutUs from "../Assets/images/about-us.svg";
import hometbn from "../Assets/images/home-btn.svg";
import { Link } from "react-router-dom";
const AboutUs = () => {
  return (
    <>
    <div className={`container animate__animated animate__fadeInRight`}>
      <div className="first-section-about">
        <div className="btn-home">
         <Link to={'/'}>
          <img src={hometbn} width="35" height="35" alt="" />
         </Link>
         <span className="mt-1">Home</span>
        </div>
        <h1 className="about-us-head">About Us</h1>
      </div>
      <div className="about-us-section">
        <div className="left-section-about">
          <p>
            At Cipher Development, we are passionate about creating innovative
            software solutions that empower businesses to thrive in the digital
            age. With our team of highly skilled developers and cutting-edge
            technologies, we deliver custom software development services
            tailored to meet the unique needs of our clients. From web design
            and development to mobile app development and digital marketing, we
            offer comprehensive solutions that drive growth and success. With a
            focus on quality, reliability, and customer satisfaction, we strive
            to exceed expectations and deliver exceptional results. Partner with
            Cipher Development and unlock the full potential of your business.
          </p>
        </div>
        <div className="right-section-about">
          <img src={aboutUs} alt="" />
        </div>
      </div>
    </div>
    </>
  );
};

export default AboutUs;
