import React, { useState } from "react";
import mob from "../Assets/images/mobile.svg";
import soft from "../Assets/images/software.svg";
import devops from "../Assets/images/devops.svg";
import web from "../Assets/images/webanddesign.svg";
import outsorcing from "../Assets/images/outsourcing.svg";
import digital from "../Assets/images/digital marketing.svg";
import blockchain from "../Assets/images/blockchain&dapps.svg";
import logoWhite from "../Assets/images/White-New.svg";
import logoBlack from "../Assets/images/Black-New.svg";
import "./landing.css";
import { Link } from "react-router-dom";
const Landing = () => {
  const [currentlyHoveredItem, setCurrentlyHoveredItem] = useState("");
  const [softDevClass, setSoftDevClass] = useState("");
  const [mobDevClass, setMobDevClass] = useState("");
  const [devopsClass, setDevopsClass] = useState("");
  const [blockchainClass, setBlockChainClass] = useState("");
  const [webDesingClass, setWebDesingClass] = useState("");
  const [outsourcingClass, setOutsourcingClass] = useState("");
  const [digitalMarketingClass, setDigitalMarketingClass] = useState("");
  const [mainHeadingHoverClass, setMainHeadingHoverClass] = useState("");

  const handleMouseEnter = async (componentName) => {
    setCurrentlyHoveredItem(componentName);
    setMainHeadingHoverClass("mainHeadingHover");
    if (componentName !== "softDev") {
      setSoftDevClass("softDevHover");
    }
    if (componentName !== "mobdev") {
      setMobDevClass("mobDevHover");
    }
    // if (componentName !== 'devops') {
    // }
    setDevopsClass("devopsHover");
    if (componentName !== "blockchain") {
      setBlockChainClass("blockchainHover");
    }
    setWebDesingClass("webdesignHover");
    setOutsourcingClass("outsourcingHover");
    if (componentName !== "digitalMarketing") {
      setDigitalMarketingClass("digitalMarketingHover");
    }
  };

  const handleMouseLeave = async (e) => {
    setCurrentlyHoveredItem("");
    setMainHeadingHoverClass("");
    setSoftDevClass("");
    setMobDevClass("");
    setDevopsClass("");
    setBlockChainClass("");
    setWebDesingClass("");
    setOutsourcingClass("");
    setDigitalMarketingClass("");
  };

  return (
    <>
      <div className="HomePage-bg container">
        <div className="itemscontainer animate__animated animate__fadeIn">
          <div
            className={`itemBox soft-dev ${softDevClass}`}
            onMouseEnter={() => handleMouseEnter("softDev")}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className={
                currentlyHoveredItem === "softDev"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "softDev" && (
                <img height="170" width="170" src={soft} alt="" />
              )}
              {currentlyHoveredItem === "softDev" && (
                <img height="120" width="120" src={soft} alt="" />
              )}
              {currentlyHoveredItem === "softDev" && (
                <label>
                  Drive your business towards success with our customized
                  software development solutions
                </label>
              )}
              <Link className="text-decoration" to={"./soft-dev"}>
                {currentlyHoveredItem !== "softDev" ? (
                  <h2 className="heading2">Software Development</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div
            className={`itemBox mob-game-dev ${mobDevClass}`}
            onMouseEnter={() => handleMouseEnter("mobdev")}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className={
                currentlyHoveredItem === "mobdev"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "mobdev" && (
                <img height="170" width="170" src={mob} alt="" />
              )}
              {currentlyHoveredItem === "mobdev" && (
                <img height="120" width="120" src={mob} alt="" />
              )}
              {currentlyHoveredItem === "mobdev" && (
                <label>
                  {" "}
                  Our mobile app development services specialize in creating
                  iOS,Android, and cross-platform applications
                </label>
              )}
              <Link className="text-decoration" to={"./games-apps"}>
                {currentlyHoveredItem !== "mobdev" ? (
                  <h2 className="heading2">Mob Games & App Dev</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div
            className={`itemBox dev-ops ${devopsClass}`}
            onMouseEnter={() => handleMouseEnter("devops")}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className={
                currentlyHoveredItem === "devops"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "devops" && (
                <img height="170" width="170" src={devops} alt="" />
              )}
              {currentlyHoveredItem === "devops" && (
                <img height="120" width="120" src={devops} alt="" />
              )}
              {currentlyHoveredItem === "devops" && (
                <label>
                  Enhance the resilience and efficiency of your enterprise-grade
                  products with Cipher top-tier DevOps services...
                </label>
              )}
              <Link className="text-decoration" to={"./dev-ops"}>
                {currentlyHoveredItem !== "devops" ? (
                  <h2 className="heading2">Dev Ops</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div className="second-section-headings">
            {/* <h1 className={`cipherh ${mainHeadingHoverClass}`}>CIPHER</h1> */}
            <h1 className={`cipherh logo-main`}>
              {mainHeadingHoverClass && (
                <img src={logoBlack} className="logo-main " alt="logo" />
              )}
              {!mainHeadingHoverClass && (
                <img src={logoWhite} className="logo-main " alt="logo" />
              )}
            </h1>
            <span>Your one stop for all</span>
            <br />
            <span>Technology and Business Services</span>
            <div className="main-buttons">
              <Link className="text-decoration" to={"./clients"}>
                <button className="portfolio-btn">Clients</button>
              </Link>
              <Link className="text-decoration" to={"./how-we-work"}>
                <button className="work-btn"> How We Work</button>
              </Link>
            </div>
          </div>
          <div
            className={`itemBox blockchain ${blockchainClass}`}
            onMouseEnter={() => handleMouseEnter("blockchain")}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className={
                currentlyHoveredItem === "blockchain"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "blockchain" && (
                <img height="170" width="170" src={blockchain} alt="" />
              )}
              {currentlyHoveredItem === "blockchain" && (
                <img height="120" width="120" src={blockchain} alt="" />
              )}
              {currentlyHoveredItem === "blockchain" && (
                <label>
                  Blockchain technology revolutionizes industries by providing
                  secure and transparent decentralized solutions for data
                  management...
                </label>
              )}
              <Link className="text-decoration" to={"./block-chain"}>
                {currentlyHoveredItem !== "blockchain" ? (
                  <h2 className="heading2">Blockchain & Dapps</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div
            className={`itemBox web-design ${webDesingClass}`}
            onMouseEnter={() => handleMouseEnter("webDesign")}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className={
                currentlyHoveredItem === "webDesign"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "webDesign" && (
                <img height="170" width="170" src={web} alt="" />
              )}
              {currentlyHoveredItem === "webDesign" && (
                <img height="120" width="120" src={web} alt="" />
              )}
              {currentlyHoveredItem === "webDesign" && (
                <label>
                  Cipher, with its extensive expertise and cohesive team,
                  specializes in delivering intuitive and modern websites...
                </label>
              )}
              <Link className="text-decoration" to={"./web-design"}>
                {currentlyHoveredItem !== "webDesign" ? (
                  <h2 className="heading2">Web Design & Dev</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div
            className={`itemBox outsourcing ${outsourcingClass}`}
            onMouseEnter={() => handleMouseEnter("outsourcing")}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className={
                currentlyHoveredItem === "outsourcing"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "outsourcing" && (
                <img height="170" width="170" src={outsorcing} alt="" />
              )}
              {currentlyHoveredItem === "outsourcing" && (
                <img height="120" width="120" src={outsorcing} alt="" />
              )}
              {currentlyHoveredItem === "outsourcing" && (
                <label>
                  your strategic partner for outsourcing solutions that drive
                  business growth and success...
                </label>
              )}
              <Link className="text-decoration" to={"./outsourcing"}>
                {currentlyHoveredItem !== "outsourcing" ? (
                  <h2 className="heading2">Outsourcing</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div
            className={`itemBox digit-marketing ${digitalMarketingClass}`}
            onMouseEnter={() => handleMouseEnter("digitalMarketing")}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className={
                currentlyHoveredItem === "digitalMarketing"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "digitalMarketing" && (
                <img height="170" width="170" src={digital} alt="" />
              )}
              {currentlyHoveredItem === "digitalMarketing" && (
                <img height="120" width="120" src={digital} alt="" />
              )}
              {currentlyHoveredItem === "digitalMarketing" && (
                <label>
                  Our startup marketing agency adopts a comprehensive and
                  integrated approach...
                </label>
              )}
              <Link className="text-decoration" to={"./marketing"}>
                {currentlyHoveredItem !== "digitalMarketing" ? (
                  <h2 className="heading2">Digital Marketing</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
        </div>
        {/* mobile */}

        <div className="itemscontainerMobile animate__animated animate__fadeIn mt-4">
          <div className="second-section-headings-mobile">
            <h1 className={`cipherh`}><img src={logoWhite} alt="" /></h1>
            <span>Your one stop for all</span>
            <br />
            <span>Technology and Business Services</span>
            <div
              className="main-buttons"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link className="text-decoration" to={"./clients"}>
                <button className="portfolio-btn">Clients</button>
              </Link>
              <Link className="text-decoration" to={"./how-we-work"}>
                <button className="work-btn"> <span>How We Work</span> </button>
              </Link>
            </div>
          </div>
          <div>
            <div style={{marginTop: '30px'}}>
              <img height="120" width="120" src={soft} alt="" />
              {currentlyHoveredItem === "softDev" && (
                <label>
                  Drive your business towards success with our customized
                  software development solutions
                </label>
              )}
              <Link className="text-decoration" to={"./soft-dev"}>
                {currentlyHoveredItem !== "softDev" ? (
                  <h2 className="heading2">Software Development</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div>
            <div style={{marginTop: '40px'}}>
              {currentlyHoveredItem !== "mobdev" && (
                <img height="170" width="170" src={mob} alt="" />
              )}
              {currentlyHoveredItem === "mobdev" && (
                <img height="120" width="120" src={mob} alt="" />
              )}
              {currentlyHoveredItem === "mobdev" && (
                <label>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore
                </label>
              )}
              <Link className="text-decoration" to={"./games-apps"}>
                {currentlyHoveredItem !== "mobdev" ? (
                  <h2 className="heading2">Mob Games & App Dev</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div style={{marginTop: '40px'}}>
            <div
              className={
                currentlyHoveredItem === "devops"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "devops" && (
                <img height="170" width="170" src={devops} alt="" />
              )}
              {currentlyHoveredItem === "devops" && (
                <img height="120" width="120" src={devops} alt="" />
              )}
              {currentlyHoveredItem === "devops" && (
                <label>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor
                </label>
              )}
              <Link className="text-decoration" to={"./dev-ops"}>
                {currentlyHoveredItem !== "devops" ? (
                  <h2 className="heading2">Dev Ops</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>

          <div style={{marginTop: '40px'}}>
            <div
              className={
                currentlyHoveredItem === "blockchain"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "blockchain" && (
                <img height="170" width="170" src={blockchain} alt="" />
              )}
              {currentlyHoveredItem === "blockchain" && (
                <img height="120" width="120" src={blockchain} alt="" />
              )}
              {currentlyHoveredItem === "blockchain" && (
                <label>
                  Blockchain technology revolutionizes industries by providing
                  secure and transparent decentralized solutions for data
                  management...
                </label>
              )}
              <Link className="text-decoration" to={"./block-chain"}>
                {currentlyHoveredItem !== "blockchain" ? (
                  <h2 className="heading2">Blockchain & Dapps</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div style={{marginTop: '40px'}}>
            <div
              className={
                currentlyHoveredItem === "webDesign"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "webDesign" && (
                <img height="170" width="170" src={web} alt="" />
              )}
              {currentlyHoveredItem === "webDesign" && (
                <img height="120" width="120" src={web} alt="" />
              )}
              {currentlyHoveredItem === "webDesign" && (
                <label>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor
                </label>
              )}
              <Link className="text-decoration" to={"./web-design"}>
                {currentlyHoveredItem !== "webDesign" ? (
                  <h2 className="heading2">Web Design & Dev</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div style={{marginTop: '40px'}}>
            <div
              className={
                currentlyHoveredItem === "outsourcing"
                  ? "divTransition"
                  : "divDefaultTransition"
              }
            >
              {currentlyHoveredItem !== "outsourcing" && (
                <img height="170" width="170" src={outsorcing} alt="" />
              )}
              {currentlyHoveredItem === "outsourcing" && (
                <img height="120" width="120" src={outsorcing} alt="" />
              )}
              {currentlyHoveredItem === "outsourcing" && (
                <label>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor
                </label>
              )}
              <Link className="text-decoration" to={"./outsourcing"}>
                {currentlyHoveredItem !== "outsourcing" ? (
                  <h2 className="heading2">Outsourcing</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
          <div style={{marginTop: '40px', marginBottom: '50px'}}>
            <div>
              {currentlyHoveredItem !== "digitalMarketing" && (
                <img height="170" width="170" src={digital} alt="" />
              )}
              {currentlyHoveredItem === "digitalMarketing" && (
                <img height="120" width="120" src={digital} alt="" />
              )}
              {currentlyHoveredItem === "digitalMarketing" && (
                <label>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor
                </label>
              )}
              <Link className="text-decoration" to={"./marketing"}>
                {currentlyHoveredItem !== "digitalMarketing" ? (
                  <h2 className="heading2">Digital Marketing</h2>
                ) : (
                  <label className="mt-2">
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "#000",
                      }}
                    >
                      Read More
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      {" "}
                      &nbsp; &rarr;
                    </span>
                  </label>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
