import React, { useState } from "react";
import { Link } from "react-router-dom";
import hometbn from "../Assets/images/home-btn.svg";
import Illustration from "../Assets/images/Illustration.svg";
import "./modulesCss/contactUs.css";
import { Form, Button } from "react-bootstrap";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.email.length < 5) {
      errors.email = "Provide a valid email address";
    } else {
      errors.email = '';
    }
    if (inputValues.message.length < 5) {
      errors.message = "Please provide more information";
    } else {
      errors.message = '';
    }
    setErrors(errors);
    return errors;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendToServer = async (payload) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };
    fetch('https://cipher-utils.onrender.com/forward-mail', requestOptions)
      .then(response => response.json())
      .then(data => {
        setSubmitting(false);
        setShowSuccess(true);
        console.log('data', data);
        setTimeout(() => {
          setFormData({
            name: "",
            email: "",
            message: ""
          })
          setShowSuccess(false);
        }, 3500);
      }).catch(err => {
        setSubmitting(false);
        console.log('err sendToServer', err);
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload = {
      subject: `Cipher Website Lead (${formData.name})`,
      email: formData.email,
      body: formData.message,
    }
    let errors = validateValues(formData);
    if (!errors.email && !errors.message) {
      console.log('payload', payload);
      console.log(formData);
      setSubmitting(true);
      sendToServer(payload);
    }
  };

  return (<>
    <div className="container page-enter" id="contact-us-form">
      <div className="first-section-about">
        <div className="btn-home">
          <Link to={"/"}>
            <img src={hometbn} width="35" height="35" alt="" />
          </Link>
          <span className="mt-1">Home</span>
        </div>
        <h1 className="primary-head">Contact Us</h1>
      </div>
      <div className="container-contact-us">
        <div className="left-portion-c">
          <Form>
            <div className="contact-radio-btn">
              <Form.Group controlId="formRadioButtons">
                <div className="d-flex">
                  <div>
                    <p style={{fontWeight: "bold", fontSize: "1.3rem"}}>Say Hi</p>
                  </div>
                  
                </div>
                {showSuccess && (
                  <div>
                    <p style={{fontWeight: "bold", fontSize: "1.3rem", color: "green"}}>We've got your Message. We will get back to You!</p>
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="text-start text-muted">
              <Form.Group controlId="formBasicName">
                {" "}
                {/* Change controlId to formBasicName */}
                <Form.Label className="form-labels-c">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  required
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="form-labels-c">
                  Email address*
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <p className="form-error-text">{errors.email}</p>
              <Form.Group
                className=""
                controlId="listingForm.ControlTextarea1"
              >
                <Form.Label className="form-labels-c">Message*</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <p className="form-error-text mb-2">{errors.message}</p>
            </div>
            <div>
              <Button
                className="contact-btn mt-2"
                type="submit"
                disabled={submitting}
                onClick={handleSubmit}
              >
                Send Message
              </Button>
            </div>
          </Form>
          {submitting && (
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          )}
        </div>
        <div className="right-portion-c">
          <img className="contact-img" src={Illustration} alt="" />
        </div>
      </div>
    </div>
    </>
  );
};

export default Contact;
