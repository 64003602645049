import React from "react";
import twetter from "../Assets/images/tweeter.svg";
import facebook from "../Assets/images/facebook.svg";
import "./footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer
      className="bg-dark text-center text-white main-footer-heading"
      style={{ bottom: 0 }}
    >
      <div className="container ">
        <section className="">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0 d-flex">
              <div className="list-unstyled mb-0 link-texts">
                <li className="nav-item">
                  <Link to={"/about-us"} className="text-white nav-link">
                    About Us
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/contact"} className="text-white nav-link">
                    Contact
                  </Link>
                </li>
              </div>
            </div>
            <div className="icons-footer">
              <a
                style={{ padding: "6px 8px" }}
                className="btn btn-floating mb-2"
                href="#!"
                role="button"
              >
                <img height="27" width="27" src={twetter} alt="" />
              </a>
              <a
                style={{ padding: "6px 8px" }}
                className="btn btn-floating mb-2"
                href="#!"
                role="button"
              >
                <img height="27" width="27" src={facebook} alt="" />
              </a>
            </div>
          </div>
        </section>
      </div>
      <div
        className="text-center p-3 footer-reserved"
        style={{ backgroundColor: "#1B1C21" }}
        color="#2D8CFF"
      >
        © 2023 Cipher, All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
