import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import hometbn from "../Assets/images/home-btn.svg";
import { scrollToTop } from "../App";

const Services = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container container-two-dev">
      <div className="d-flex first-container-dev">
        <div className="btn-home btn-home-position">
          <Link to={"/"}>
            <img src={hometbn} width="35" height="35" alt="" />
          </Link>
          <span className="mt-1">Home</span>
        </div>
        <div className="first-section-dev-outsourcing text-start">
          <h1>Outsourcing Excellence with Cipher Development</h1>
          <p>
            Welcome to Cipher Development, your strategic partner for
            outsourcing solutions that drive business growth and success.
            Discover the benefits of entrusting your projects to a leading
            technology and business services provider.
          </p>
        </div>
      </div>
      <div className="heading-bg mt-4">
        <h2 className="services-text">Why Outsource with Cipher?</h2>
        <span className="blue-line-web"></span>
      </div>
      <div className="second-section-software text-start mt-3">
        <h2>Expertise in Software Development</h2>
        <p>
          Leverage our comprehensive software development solutions tailored to
          meet your unique requirements. From ideation to support, our skilled
          team guides you through every step of the development lifecycle.
        </p>
        <h2>Optimized Efficiency</h2>
        <p>
          Enhance the efficiency and quality of your software projects by
          outsourcing to Cipher. Our rigorous screening process ensures that
          only the best developers contribute to your success.
        </p>
        <h2>Managed Services & DevOps Support</h2>
        <p>
          Focus on your core business while we provide enterprise-level support
          through our managed services and DevOps approach. Trust us to handle
          your mission-critical environments with precision.
        </p>
        <h2>Mobile App and Game Development</h2>
        <p>
          Seamlessly integrate mobile solutions into your business strategy with
          our expertise in iOS, Android, and cross-platform applications.
          Explore innovative mobile game development services tailored to your
          needs.
        </p>
        <h2>Blockchain and Crypto Solutions:</h2>
        <p>
          Revolutionize your industry with our end-to-end blockchain solutions.
          From NFTs to Smart Contracts and Dapps, Cipher is at the forefront of
          secure and transparent decentralized technology.
        </p>
        <h2>Web Design and Development:</h2>
        <p>
          Elevate your online presence with our intuitive and modern web
          solutions. Our cohesive team specializes in crafting websites, web
          portals, and products that drive digital transformation and optimize
          business workflows.
        </p>
        <h2>Digital Strategy and Consulting:</h2>
        <p>
          Consult with our experienced R&D team for comprehensive product
          consultation services. Whether it's web or mobile solutions, we guide
          you through the entire process, helping you choose the most suitable
          technologies for your project.
        </p>
        <h2>UI/UX Design and Front-end Development:</h2>
        <p>
          Immerse your audience in seamless user experiences with our senior
          product designers. Our front-end development team utilizes advanced
          technologies to create responsive and user-friendly interfaces for web
          and mobile platforms.
        </p>
      </div>
    </div>
  );
};

export default Services;
