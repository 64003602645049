import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import hometbn from "../Assets/images/home-btn.svg";
import block from "../Assets/images/block-chain-details.svg";
import "./modulesCss/GeneralForPages.css";
import { scrollToTop } from "../App";
const Blockchain = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container container-two-block">
      <div className="d-flex first-container-dev">
        <div className="btn-home btn-home-position">
          <Link to={"/"}>
            <img src={hometbn} width="35" height="35" alt="" />
          </Link>
          <span className="mt-1">Home</span>
        </div>
        <div className="first-section-dev text-start">
          <h1>
            BlockChain <br />& Crypto
          </h1>
          <p>
            Blockchain technology revolutionizes industries by providing secure
            and transparent decentralized solutions for data management,
            financial transactions, and supply chain management. Our
            comprehensive services include custom development programs for NFTs,
            Smart Contracts, Crypto, and Dapps, offering an end-to-end solution.
          </p>
        </div>
        <div className="right-section-dev">
          <img className="img-section-right-block" src={block} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Blockchain;
