import React from "react";
import { Route, Outlet, Routes, Link } from "react-router-dom";
import Landing from "./Components/Landing";
import AboutUs from "./modules/AboutUs";
import Contact from "./modules/Contact";
import HowWeWork from "./modules/HowWeWork";
import Portfolio from "./modules/Portfolio";
import Services from "./modules/Outsourcing";
import SoftDev from "./modules/SoftDev";
import DevOps from "./modules/DevOps";
import WebDesign from "./modules/WebDesign";
import MobGamesApp from "./modules/MobGamesApp";
import DigitalMarketing from "./modules/DigitalMarketing";
import Blockchain from "./modules/Blockchain";
import CaseStudy from "./modules/CaseStudy";
import CaseStudyIride from "./modules/CaseStudyIride";
import CaseStudyHelix from "./modules/CaseStudyHelix";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<Landing />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="how-we-work" element={<HowWeWork />} />
        <Route path="clients" element={<Portfolio />} />
        <Route path="outsourcing" element={<Services />} />
        <Route path="Soft-dev" element={<SoftDev />} />
        <Route path="dev-ops" element={<DevOps />} />
        <Route path="web-design" element={<WebDesign />} />
        <Route path="games-apps" element={<MobGamesApp />} />
        <Route path="marketing" element={<DigitalMarketing />} />
        <Route path="block-chain" element={<Blockchain />} />
        <Route path="img-details/0" element={<CaseStudy />} />
        <Route path="img-details/3" element={<CaseStudy />} />
        <Route path="img-details/1" element={<CaseStudyIride />} />
        <Route path="img-details/4" element={<CaseStudyIride />} />
        <Route path="img-details/2" element={<CaseStudyHelix />} />
        <Route path="img-details/5" element={<CaseStudyHelix />} />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

function NoMatch() {
  return (
    <div style={{ marginTop: "15%", textAlign: "center" }}>
      <h2>404 Page not found!</h2>
      <p style={{ fontSize: "1.3rem", marginTop: "18px" }}>
        <Link to="/">&nbsp;Go to the home page</Link>
      </p>
    </div>
  );
}
export default MainRoutes;
